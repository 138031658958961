export class PathUtil {
    public static verify: string = 'oauth/verify';
    public static upload: string = 'upload/UploadMaterial';

    //shared
    public static getHistoryMaterials: string = 'shared/GetHistoryMaterials';//获取历史附件
    public static getTransactionTypes: string = 'shared/getTransactionTypes';//获取交易类型
    public static getBusinessApps: string = 'shared/getBusinessApps';//获取交易应用下拉列表
    public static getRiskLevels: string = 'shared/getRiskLevels';//获取风险等级下拉列表
    public static getRiskReviewStatus: string = 'shared/getRiskReviewStatus';//获取风险等级下拉列表
    public static getBankCardModes: string = 'shared/getBankCardModes';//获取银行卡类型下拉列表
    public static getTransactionCurrencies: string = 'shared/getTransactionCurrencies';//获取交易币种下拉列表
    public static getMaterials: string = 'shared/getMaterials';
    public static uploadMaterial: string = 'shared/uploadMaterial';
    public static removeMaterial: string = 'shared/removeMaterial';
    public static getRiskRatingRules: string = 'shared/getRiskRatingRules';//获取风险评级规则
    public static getBusiness: string = 'shared/getBusiness';//获取所有业务申请类型
    public static getAuditCommentStatus: string = 'shared/GetAuditCommentStatus';//获取审核类型/审核结果




    //mainbody

    public static gePendingRickTaskStatistics : string = 'mainBody/GePendingRickTaskStatistics';//获取待处理的任务统计
    public static getMBModes : string = 'mainBody/GetMBModes';//获取主体类型
    public static getEnumRiskType : string = 'mainBody/GetEnumRiskType';//获取审核类型
    public static addRiskTask : string = 'mainBody/AddRiskTask';//获取审核记录列表
    public static getRickTaskPageList : string = 'mainBody/GetRickTaskPageList';//获取审核记录列表
    public static getRiskTaskById : string = 'mainBody/GetRiskTaskById';//获取审查任务详情   
    public static getEnumRiskTaskStatus : string = 'mainBody/GetEnumRiskTaskStatus';//获取审核状态
    public static getChangeAuditRickTaskPageList : string = 'mainBody/GetChangeAuditRickTaskPageList';//获取初审列表
    public static getFirstAuditRickTaskPageList : string = 'mainBody/GetFirstAuditRickTaskPageList';//获取初审列表
    public static getPeriodicAuditRickTaskPageList : string = 'mainBody/GetPeriodicAuditRickTaskPageList';//获取周期审查列表
    public static getExpireAuditRickTaskPageList  : string = 'mainBody/GetExpireAuditRickTaskPageList';//获取过期审查列表
    public static riskTaskHandle  : string = 'mainBody/RiskTaskHandle ';//审查任务处理
    public static getRiskResultRecordTypeList: string = 'mainBody/GetRiskTaskRecordTypeList';//获取风控结果审核集合
    public static saveRiskLevelRank: string = 'mainBody/UpdateReview';//保存风险等级数据
    public static savePersonalFilter: string = 'mainbody/savePersonalFilter';
    public static loadPersonalFilter: string = 'mainbody/loadPersonalFilter';
    public static saveCompanyFilter: string = 'mainbody/savecompanyFilter';
    public static loadCompanyFilter: string = 'mainbody/loadCompanyFilter';
    public static getRiskStatus: string = "mainbody/getRiskStatus";
    public static getRiskItemStatus: string = "mainbody/getRiskItemStatus";
    public static getCheckStatus: string = "mainbody/getCheckStatus";
    public static getNamelist: string = "mainbody/getNamelist";
    public static getRiskProcessStatus: string = "mainbody/getRiskProcessStatus";
    public static listCompanies: string = 'mainbody/listCompanies';
    public static getCompany: string = 'mainbody/getCompany';
    public static listUsers: string = 'mainbody/listUsers';
    public static setMainbodyRemark: string = 'mainbody/setRemark';
    public static getUser: string = 'mainbody/getUser';
    public static listCheckRecords: string = 'mainbody/listCheckRecords';
    public static saveCheckRecord: string = 'mainbody/saveCheckRecord';
    public static listCheckRecordDetails: string = 'mainbody/listCheckRecordDetails';
    public static getCheckRecordDetailMaterials: string = "mainbody/getCheckRecordDetailMaterials"
    public static listRiskRecords: string = 'mainbody/listRiskRecords';
    public static getRiskScoreDetail: string = 'mainbody/getRiskScoreDetail';
    public static saveRiskItemResult: string = 'mainbody/saveRiskItemResult';
    public static saveRiskResult: string = 'mainbody/saveRiskResult';
    public static getRiskRecordDetail: string = 'mainbody/getRiskRecordDetail';
    public static getWaitForCheckMainBodyList: string = 'mainbody/getWaitForCheckMainBodyList';
    public static updateMainbodyFilterCircle: string = 'mainbody/updateMainbodyFilterCircle';
    public static getRelateCompanyList: string = 'mainbody/GetRelateCompanyList';
    public static refreshUserInfo: string = 'mainbody/RefreshUserInfo';//刷新证件信息

    public static getAuditTaskList: string = 'AuditTask/GetPageList';//获取审核任务列表
    public static getAuditTaskDetailInfo: string = 'AuditTask/GetDetailInfo';//获取任务详情
    public static getEnumAuditTaskType: string = 'AuditTask/GetEnumAuditTaskType';//获取任务类型枚举
    public static getEnumAuditTaskProcessStatus: string = 'AuditTask/GetEnumAuditTaskProcessStatus';//获取任务处理状态枚举


    
    //negativelist
    public static getnegativelistcategories = 'negativelist/getnegativelistcategories';
    public static getriskmarks = 'negativelist/getriskmarks';

    public static saveStyleConfig: string = 'negativelist/savestyleconfig';
    public static liststyleconfig: string = 'negativelist/liststyleconfig';
    public static removestyleconfig: string = 'negativelist/removestyleconfig';

    public static saveNegativeList: string = 'negativelist/savenegativelist';
    public static listNegativeList: string = 'negativelist/listnegativelist';
    public static removeNegativeList: string = 'negativelist/removenegativelist';
    public static loadLogs: string = 'negativelist/loadlogs';
    public static loadMaterials: string = 'negativelist/loadmaterials';
    public static saveAuditRecord: string = 'negativelist/saveauditrecord';

    public static listDowJonesList: string = 'negativelist/listdowjoneslist';
    public static getDowJonesDetail: string = 'negativelist/listgetdowjonesdetail';
   

    //greylist
    public static getgreylistcategory: string = 'greylist/getgreylistcategory';
    //monitor
    public static getATMonitorOrderRule: string = 'monitor/GetATMonitorOrderRule';//获取订单监控配置
    public static saveATMonitorOrder: string = 'monitor/SaveATMonitorOrder';//保存订单监控配置
    public static getBusinessAndApps: string = 'monitor/getBusinessAndApps';//获取场景和服务
    public static addAPPTradeMonitor: string = 'monitor/addAPPTradeMonitor';//添加监控交易
    public static switchAPPTradeMonitorStatus: string = 'monitor/switchAPPTradeMonitorStatus';//更改交易监控状态
    public static getAPPTradeMonitorList: string = 'monitor/getAPPTradeMonitorList';//获取监控列表
    public static getATMonitorBasicRuleList: string = 'monitor/getATMonitorBasicRuleList';//获取基础交易监控规则
    public static getATMonitorDailyRuleList: string = 'monitor/getATMonitorDailyRuleList';//获取日交易监控规则
    public static getATMonitorMonthlyRuleList: string = 'monitor/getATMonitorMonthlyRuleList';//获取月交易监控规则
    public static getATMonitorYearlyRuleList: string = 'monitor/getATMonitorYearlyRuleList';//获取年交易监控规则
    public static getATBasicMonitorRange: string = 'monitor/getATBasicMonitorRange';//获取基础交易监控范围
    public static getATDMYMonitorRange: string = 'monitor/getATDMYMonitorRange';//获取日/月/年交易监控范围
    public static getATBasicMonitorContent: string = 'monitor/getATBasicMonitorContent';//获取基础交易监控内容
    public static getATDMYMonitorContent: string = 'monitor/getATDMYMonitorContent';//获取日/月/年交易监控内容
    public static getATMonitorOperator: string = 'monitor/getATMonitorOperator';//获取交易监控运算标准符号
    public static addATMonitorRuleByType: string = 'monitor/addATMonitorRuleByType';//添加基础/日/月/年交易监控规则
    public static switchATMonitorRule: string = 'monitor/switchATMonitorRule';//启用/禁用-基础/日/月/年交易监控规则
    public static saveATMonitorBasicRuleVersion: string = 'monitor/saveATMonitorBasicRuleVersion';//添加基础交易监控规则版本
    public static saveATMonitorDailyRuleVersion: string = 'monitor/saveATMonitorDailyRuleVersion';//添加日交易监控规则版本
    public static saveATMonitorMonthlyRuleVersion: string = 'monitor/saveATMonitorMonthlyRuleVersion';//添加月交易监控规则版本
    public static saveATMonitorYearlyRuleVersion: string = 'monitor/saveATMonitorYearlyRuleVersion';//添加年交易监控规则版本
    public static getATMonitorBasicRuleVersionList: string = 'monitor/getATMonitorBasicRuleVersionList';//获取基础交易监控规则-版本列表
    public static getATMonitorDailyRuleVersionList: string = 'monitor/getATMonitorDailyRuleVersionList';//获取日交易监控规则-版本列表
    public static getATMonitorMonthlyRuleVersionList: string = 'monitor/getATMonitorMonthlyRuleVersionList';//获取月交易监控规则-版本列表
    public static getATMonitorYearlyRuleVersionList: string = 'monitor/getATMonitorYearlyRuleVersionList';//获取年交易监控规则-版本列表
    public static enableATMonitorRuleVersion: string = 'monitor/enableATMonitorRuleVersion';//启用-基础设置/日/月/年交易监控规则版本
    public static removeATMonitorRuleVersion: string = 'monitor/removeATMonitorRuleVersion';//删除基础设置/日/月/年交易监控规则版本
    public static getATDMYMonitorUnit: string = 'monitor/getATDMYMonitorUnit';//获取日/月/年交易监控阈值单位
    public static getATMonitorFilterRule: string = 'monitor/getATMonitorFilterRule';//获取交易监控开通过滤参数
    public static switchATMonitorFilterRuleByType: string = 'monitor/switchATMonitorFilterRuleByType';//启用/禁用-交易监控过滤参数
    public static saveATMonitorFilterRuleKeysByType: string = 'monitor/saveATMonitorFilterRuleKeysByType';//保存关键词-交易监控过滤参数
    public static saveATMonitorFilterURLAndRecheckPeriod: string = 'monitor/saveATMonitorFilterURLAndRecheckPeriod';//保存网站链接和审查周期-交易监控过滤参数
    public static getMonitorOperationLogs: string = 'monitor/getMonitorOperationLogs';//获取交易监控操作日志
    public static getATMonitorRiskScoreList: string = "monitor/getATMonitorRiskScoreList";//获取风险分值配置
    public static updateATMonitorRiskScore: string = "monitor/updateATMonitorRiskScore";//修改评级监控分值配置数据
    public static batchAddATMonitorRiskScore: string = "monitor/batchAddATMonitorRiskScore";//批量添加评级监控分值配置数据
    public static removeATMonitorRiskScore: string = "monitor/removeATMonitorRiskScore";//移除评级监控分值配置数据 
    public static getRiskScoreSimpleInfoListByATId: string = 'monitor/getRiskScoreSimpleInfoListByATId';//获取风险分值配置(供业务监控使用)

    //riskRate 
    public static getEquityStructureChildren: string = 'riskRate/GetEquityStructureChildren';//获取股权架构等级
    public static listRiskScores: string = 'riskRate/listRiskScores';//获取计分记录
    public static getReviewNameList: string = 'riskRate/getReviewNameList';//获取核查名单
    public static getWaitForReviewItemsByMBId: string = 'riskRate/getWaitForReviewItemsByMBId';//根据主体ID获取待核查列表
    public static saveRiskReview: string = 'riskRate/saveRiskReview';//保存分值配置数据
    public static getRiskScoreMaterialsById: string = 'riskRate/getRiskScoreMaterialsById';//获取主体计分记录
    public static getRiskScoreList: string = 'riskRate/getRiskScoreList';//获取风险分值配置
    public static saveRiskScore: string = 'riskRate/saveRiskScore';//保存分值配置数据 
    public static removeRiskScore: string = 'riskRate/removeRiskScore';//移除分值配置数据 
    public static getRiskTargetsAssociatedList: string = 'riskRate/getRiskTargetsAssociatedList';//获取风险指标关联下拉列表 
    public static getRiskRateList: string = 'riskRate/getRiskRateList';//获取风险等级配置
    public static saveRiskRank: string = 'riskRate/saveRiskRank';//保存等级配置数据
    public static getRiskScoreOperator: string = 'riskRate/getRiskScoreOperator';//获取风险评价分值运算标准符号 

    //business

    public static appApplyGetRiskTaskRecordTypeList: string = 'appApply/GetRiskTaskRecordTypeList';//获取风控结果审核集合
    public static appApplyRiskTaskHandle: string = 'appApply/RiskTaskHandle';//审查任务处理
    public static getAppApplyRickTaskPageList: string = 'appApply/GetRickTaskPageList';//获取店铺授权有效期列表
    public static getAppApplyList: string = 'appApply/getAppApplyList';//获取业务申请列表
    public static getAppApplyDetailByAppId: string = 'appApply/getAppApplyDetailByAppId';//获取业务申请详情信息
    public static saveAppApplyRiskResult: string = 'appApply/saveAppApplyRiskResult';//保存业务申请风控审核信息
    public static getAppApplyCheckListByAppId: string = 'appApply/getAppApplyCheckListByAppId';//根据AppApplyType.Guid获取业务审查列表
    public static GetAppApplyCheckMaterialsById: string = 'appApply/GetAppApplyCheckMaterialsById';//根据Id获取业务审查详情信息
    public static saveAppApplyCheckDetail: string = 'appApply/saveAppApplyCheckDetail';//新增/更新业务审查详情信息
    public static getWaitForCheckAppApplyList: string = 'appApply/getWaitForCheckAppApplyList';// 获取待审查业务申请列表 
    //transaction

    public static SetTransactionRemark: string = 'transaction/SetTransactionRemark';//添加备注
    public static saveTransactionRiskResult2: string = 'transaction/SaveTransactionRiskResult2';//提交审核结果
    public static getOrdersByTransactionId3: string = 'transaction/getOrdersByTransactionId3';//获取入账订单信息
    public static deleteOrder: string = 'transaction/deleteOrder';//删除入账订单
    public static getTransactionRiskList: string = 'transaction/GetTransactionRiskList';//获取审核列表
    public static getTransactionList: string = 'transaction/getTransactionList';//获取交易审核列表
    public static getTransactionDetailById: string = 'transaction/getTransactionDetailById';//获取交易审核详情
    public static saveTransactionRiskResult: string = 'transaction/saveTransactionRiskResult';//保存交易审核的风控信息
    public static getRiskTransactionMonitorList: string = 'transaction/getRiskTransactionMonitorList';//获取交易审核列表 
    public static saveTransactionMonitorCheck: string = 'transaction/saveTransactionMonitorCheck';//保存交易审核-交易监控的审核信息
    public static getTransactionStatus: string = 'transaction/getTransactionStatus';//获取交易状态下拉列表
    public static getRiskTaskStatus: string = 'transaction/getRiskTaskStatus';//获取交易状态下拉列表-新
    public static getTransactionHistoryList: string = 'transaction/getTransactionHistoryList';//获取交易的历史交易记录
    public static getStoreInfoByTransactionId: string = 'transaction/getStoreInfoByTransactionId';//根据交易Id获取店铺信息
    public static getSettlementPeriodListByStoreId: string = 'transaction/getSettlementPeriodListByStoreId';//根据店铺id获取结算周期列表
    public static getStoreOrderListBySPId: string = 'transaction/getStoreOrderListBySPId';//根据结算周期id获取店铺订单列表
    public static getOrdersByTransactionId: string = 'transaction/getOrdersByTransactionId';//根据交易id获取店铺订单列表
    public static getOrdersByTransactionId2: string = 'transaction/getOrdersByTransactionId2';//根据交易id获取店铺订单列表
    public static getCashInCertificateMaterialsByTransactionId: string = "transaction/getCashInCertificateMaterialsByTransactionId";//根据交易Id获取入账证明附件信息
    public static getTransactionMonitorDetailById: string = "transaction/GetTransactionMonitorDetailById";//获取交易审核的交易监控列表
    // 出账审核
    public static getTransactionList2: string = 'transaction/getTransactionList2';//获取交易审核列表
    public static getTransactionDetailById2: string = 'transaction/getTransactionDetailById2';//获取交易审核详情
    public static saveTransactionRiskResultNew2: string = 'transaction/SaveTransactionRiskResultNew2';//提交审核结果
    public static getTransactionRiskList2: string = 'transaction/GetTransactionRiskList2';//获取审核列表
    public static getRiskTransactionMonitorList2: string = 'transaction/getRiskTransactionMonitorList2';//获取交易审核列表 
    public static getTransactionMonitorDetailById2: string = "transaction/GetTransactionMonitorDetailById2";//获取交易审核的交易监控列表
    public static saveTransactionMonitorCheck2: string = 'transaction/saveTransactionMonitorCheck2';//保存交易审核-交易监控的审核信息
    public static SetTransactionRemark2: string = 'transaction/SetTransactionRemark2';//添加备注
    public static getTransactionHistoryList2: string = 'transaction/getTransactionHistoryList2';//获取交易的历史交易记录

    
    // 出账审核

    //account
    //bankaccount
    public static getBankAccountList: string = 'account/getBankAccountList';//获取收款账号列表
    public static getBankAccountDetail: string = 'account/getBankAccountDetail';//获取收款账号详情信息
    //transfer
    public static getTransferBankCardList: string = 'account/getTransferBankCardList';//获取转账卡列表
    public static getTransferBankCardDetail: string = 'account/getTransferBankCardDetail';//获取转账卡详情信息
    public static saveTransferBankCardRiskResult: string = 'account/saveTransferBankCardRiskResult';//保存转账卡风控审核信息 
    public static getTransferBankCardCheckListByWBCId: string = 'account/getTransferBankCardCheckListByWBCId';//根据TransferBankCardType.Guid获取转账卡审查列表
    public static getTransferBankCardCheckMaterialsById: string = 'account/getTransferBankCardCheckMaterialsById';//根据Id获取转账卡审查附件信息
    public static saveTransferBankCardCheckDetail: string = 'account/saveTransferBankCardCheckDetail';//新增/更新转账卡审查详情信息
    public static getTransferBankCardTransactionList: string = 'account/getTransferBankCardTransactionList';//获取转账卡的交易记录列表
    public static getTransferBankCardTransactionDetail: string = 'account/getTransferBankCardTransactionDetail';//获取转账卡的交易记录详情信息 
    public static getWaitForCheckTransferBankCardList: string = 'account/getWaitForCheckTransferBankCardList';//获取待审查转账卡列表
    //withdraw
    public static getWithdrawBankCardList: string = 'account/getWithdrawBankCardList';//获取提现卡列表
    public static getWithdrawBankCardDetail: string = 'account/getWithdrawBankCardDetail';//获取提现卡详情信息
    public static saveWithdrawBankCardRiskResult: string = 'account/saveWithdrawBankCardRiskResult';//保存提现卡风控审核信息
    public static getWithdrawBankCardCheckListByWBCId: string = 'account/getWithdrawBankCardCheckListByWBCId';//根据WithdrawBankCardType.Guid获取提现卡审查列表
    public static getWithdrawBankCardCheckMaterialsById: string = 'account/getWithdrawBankCardCheckMaterialsById';//根据Id获取提现卡审查附件信息
    public static saveWithdrawBankCardCheckDetail: string = 'account/saveWithdrawBankCardCheckDetail';//新增/更新提现卡审查详情信息
    public static getWithdrawBankCardTransactionList: string = 'account/getWithdrawBankCardTransactionList';//获取提现卡的交易记录列表
    public static getWithdrawBankCardTransactionDetail: string = 'account/getWithdrawBankCardTransactionDetail';//获取提现卡的交易记录详情信息
    public static getWaitForCheckWithdrawBankCardList: string = 'account/getWaitForCheckWithdrawBankCardList';//获取待审查提现卡列表

    //namelist
    public static getNameList: string = 'namelist/getNameList';//获取待审查提现卡列表
    public static getNameListCategories: string = 'namelist/getNameListCategories';//获取待审查提现卡列表
    public static saveNameListStyleConfig: string = 'namelist/saveNameListStyleConfig';//获取待审查提现卡列表
    public static listNameListStyleConfig: string = 'namelist/listNameListStyleConfig';//获取待审查提现卡列表
    public static removeNameListStyleConfig: string = 'namelist/removeNameListStyleConfig';//获取待审查提现卡列表
    public static listNamelistCheckRecords: string = 'namelist/listCheckRecords';//检索名单审查记录
    public static saveNamelistCheckRecord: string = 'namelist/saveCheckRecords';//保存审查记录
    public static getBlackListContentTypes: string = 'namelist/getBlackListContentTypes';//涉及内容类型
    public static getNameListCheckRecordDetail: string = 'namelist/getCheckRecordDetail';//审查记录详情
    //blackList
    public static listBlackList: string = 'namelist/listblacklist';
    public static removeBlackList: string = 'namelist/removeblacklist';
    public static saveBlackList: string = 'namelist/saveblacklist';
    public static getBlackListDetail: string = 'namelist/getBlackListDetail';
    //greyList
    public static listGreyList: string = 'namelist/listGreyList';
    public static getGreyListDetail: string = 'namelist/getGreyListDetail';
    public static listGreyListTriggers: string = 'namelist/listGreyListTriggers';
    public static getGreyListTriggerDetail: string = 'namelist/getGreyListTriggerDetail';
    public static removeGreyList: string = 'namelist/removeGreyList';
    //whiteList
    public static saveWhiteList: string = 'namelist/saveWhiteList';
    public static listWhiteList: string = 'namelist/listWhiteList';
    public static removeWhiteList: string = 'namelist/removeWhiteList';
    public static getWhiteListDetail: string = 'namelist/getWhiteListDetail';

    //dailyscreeninglist
    public static listDailyScreeningList: string = 'Dowjones/GetAlarmTaskList';
    public static getDailyScreeningDetailList: string = 'Dowjones/GetAlarmTaskDetailList';
    public static postAlarmHandle: string = 'Dowjones/AlarmHandle';
    public static getProfiles: string = 'Dowjones/GetProfiles';

     //auditcomment
     public static getAuditCommentList: string = 'auditcomment/GetList';
     public static addAuditComment: string = 'auditcomment/add';
     public static updateAuditComment: string = 'auditcomment/update';
     public static deleteAuditComment: string = 'auditcomment/delete';
}

