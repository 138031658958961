<nz-layout>
    <nz-header>
        <div class="logo">
            <a [routerLink]="['/dashboard']"><img src='../../../assets/images/logo.png'></a>
        </div>
        <div class="user" *ngIf="user">
            <div>
                <nz-avatar nzIcon="user" nzSrc="{{user.icon}}" [nzSize]="36"></nz-avatar>
                <a nz-dropdown [nzDropdownMenu]="menu">
                    <span>{{user.name}}</span>
                    <i nz-icon nzType="down"></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item>
                            <a (click)="exit()">
                                <i nz-icon nzType="logout" nzTheme="outline"></i>
                                <span class="user-action">退出登录</span>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </nz-header>
    <div style="height: calc(100% - 64px);">
        <nz-layout>
            <nz-sider [nzTrigger]="null" nzTheme="light">
                <ul nz-menu nzMode="inline">
                    <li nz-submenu nzTitle="监控设置" nzIcon="monitor">
                        <ul>
                            <li nz-menu-item [routerLink]="['/monitorsetting/mainbody']"><i nz-icon nzType="idcard"
                                    nzTheme="outline"></i>主体监控</li>
                            <li nz-menu-item [routerLink]="['/monitorsetting/business']"><i nz-icon nzType="coffee"
                                    nzTheme="outline"></i>业务监控</li>
                            <li nz-menu-item [routerLink]="['/monitorsetting/transaction']"><i nz-icon nzType="crown"
                                    nzTheme="outline"></i>交易监控</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="风险管理" nzIcon="meh">
                        <ul>
                            <li nz-menu-item [routerLink]="['/riskmanagement/mainbody']"> <i nz-icon nzType="snippets"
                                    nzTheme="outline"></i>定期审查</li>
                            <li nz-menu-item [routerLink]="['/riskmanagement/business']"> <i nz-icon nzType="filter"
                                    nzTheme="outline"></i>业务审核</li>
                            <!-- <li nz-menu-item [routerLink]="['/riskmanagement/account']"> <i nz-icon nzType="user"
                                    nzTheme="outline"></i>账户审核</li> -->
                            <li nz-menu-item [routerLink]="['/riskmanagement/transaction']"> <i nz-icon nzType="dollar"
                                    nzTheme="outline"></i>入账审核</li>
                            <li nz-menu-item [routerLink]="['/riskmanagement/payOut']"> <i nz-icon nzType="dollar"
                                    nzTheme="outline"></i>出账审核</li>
                            <!-- <li nz-menu-item [routerLink]="['/riskmanagement/quota']"> <i nz-icon nzType="funnel-plot"
                                    nzTheme="outline"></i>额度审核</li> -->
                            <!-- <li nz-menu-item [routerLink]="['/riskmanagement/regular']"> <i nz-icon nzType="field-time"
                                    nzTheme="outline"></i>定期审查</li> -->
                            <li nz-menu-item [routerLink]="['/riskmanagement/auditlist']"> <i nz-icon nzType="audit"
                                    nzTheme="outline"></i>评级核查</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="风险评级" nzIcon="alert">
                        <ul>
                            <li nz-menu-item [routerLink]="['/riskrate/ranksetting']"> <i nz-icon nzType="fund"
                                    nzTheme="outline"></i>等级配置</li>
                            <li nz-menu-item [routerLink]="['/riskrate/scoresetting']"> <i nz-icon nzType="field-number"
                                    nzTheme="outline"></i>分值配置</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="主体管理" nzIcon="team">
                        <ul>
                            <li nz-menu-item [routerLink]="['/mainbody/companylist']"><i nz-icon nzType="unordered-list"
                                    nzTheme="outline"></i>企业主体</li>
                            <li nz-menu-item [routerLink]="['/mainbody/personallist']"><i nz-icon
                                    nzType="unordered-list" nzTheme="outline"></i>个人主体</li>
                            <li nz-menu-item [routerLink]="['/mainbody/updaterecordlist']"><i nz-icon
                                    nzType="unordered-list" nzTheme="outline"></i>更新记录</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="账户管理" nzIcon="account-book">
                        <ul>
                            <li nz-menu-item [routerLink]="['/accountmanagement/bankaccount']"> <i nz-icon
                                    nzType="dollar" nzTheme="outline"></i>收款账户</li>
                            <li nz-menu-item [routerLink]="['/accountmanagement/withdraw']"> <i nz-icon
                                    nzType="credit-card" nzTheme="outline"></i>提现卡</li>
                            <li nz-menu-item [routerLink]="['/accountmanagement/transfer']"> <i nz-icon
                                    nzType="transaction" nzTheme="outline"></i>转账卡</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="名单管理" nzIcon="database">
                        <ul>
                            <li nz-menu-item [routerLink]="['/namelist/styleconfig']"> <i nz-icon nzType="setting"
                                    nzTheme="outline"></i> 类型配置</li>
                            <li nz-menu-item [routerLink]="['/namelist/blacklist']"> <i nz-icon nzType="stop"
                                    nzTheme="outline"></i>黑名单</li>
                            <li nz-menu-item [routerLink]="['/namelist/greylist']"> <i nz-icon nzType="warning"
                                    nzTheme="outline"></i>灰名单</li>
                            <li nz-menu-item [routerLink]="['/namelist/whitelist']"> <i nz-icon nzType="heart"
                                    nzTheme="outline"></i>白名单</li>
                            <!-- <li nz-menu-item [routerLink]="['/namelist/dowjoneslist']"> <i nz-icon
                                nzType="warning" nzTheme="outline"></i>名单核查</li> -->
                            <li nz-menu-item [routerLink]="['/namelist/dailyscreeninglist']"> <i nz-icon
                                    nzType="warning" nzTheme="outline"></i>筛查监控</li>
                        </ul>
                    </li>
                    <li nz-submenu nzTitle="负面清单" nzIcon="file-excel">
                        <ul>
                            <li nz-menu-item [routerLink]="['/negativelist/styleconfig']"> <i nz-icon nzType="setting"
                                    nzTheme="outline"></i> 类型配置</li>
                            <li nz-menu-item [routerLink]="['/negativelist/listmanagement']"> <i nz-icon
                                    nzType="warning" nzTheme="outline"></i>清单管理</li>
                        </ul>
                    </li>
                    <!-- <li nz-submenu nzTitle="基础系统" nzIcon="setting">
                        <ul>
                            <li nz-menu-item [routerLink]="['/sys/user']"><i nz-icon nzType="user"
                                    nzTheme="outline"></i>员工管理
                            </li>
                        </ul>
                    </li> -->
                    <li nz-submenu nzTitle="数据配置" nzIcon="file-excel">
                        <ul>
                            <li nz-menu-item [routerLink]="['/datasettinglist/auditcomment']"><span nz-icon
                                    nzType="delivered-procedure" nzTheme="outline"></span>审核评语</li>
                        </ul>
                    </li>
                </ul>
            </nz-sider>
            <nz-layout class="inner-layout">
                <nz-content style="overflow: auto;">
                    <router-outlet></router-outlet>
                </nz-content>
            </nz-layout>
        </nz-layout>
    </div>
</nz-layout>